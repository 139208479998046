(function ($) {

    var scripts = {
        'common': {
            init: function () {
                initPrimaryMenu();
                initCarousels();
            },
            finalize: function () {
            }
        },
        'woocommerce': {
            init: function () {
                initLayeredNav();
            }
        },
        'single_tc_events': {
            init: function () {
                initMap();
            }
        }
    };

    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && scripts[func];
            fire = fire && typeof scripts[func][funcname] === 'function';

            if (fire) {
                scripts[func][funcname](args);
            }
        },
        loadEvents: function () {
            UTIL.fire('common');

            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    var initPrimaryMenu = function () {

        var primary_menu = $('#menu-primary');

        $('li.menu-item-has-children > a', primary_menu).click(function (e) {
            e.preventDefault();

            var $this = $(this);

            if ($this.parent().hasClass('expanded')) {
                $this.parent().removeClass('expanded');
                $this.next().slideUp(350);
            } else {
                primary_menu.find('li.menu-item-has-children').removeClass('expanded');
                primary_menu.find('ul.sub-menu').slideUp(350);
                $this.parent().toggleClass('expanded');
                $this.next().slideToggle(350);
            }

        });

        var current_sub_item = $('.sub-menu .current-menu-item', primary_menu);
        current_sub_item.parent().show();
        current_sub_item.parent().closest('li.menu-item-has-children').toggleClass('expanded');

    };

    var initCarousels = function () {

        //@see: https://github.com/kenwheeler/slick/

        $('.events-slider > .slider').each(function () {

            var slider = $(this),
                wrapper = slider.closest('.events-slider');

            slider.slick({
                // slidesToShow: 12,
                // slidesToScroll: 1,
                infinite: true,
                // centerMode: true,
                variableWidth: true,
                appendArrows: wrapper.find('.title'),
                nextArrow: '<div class="slick-next"></div>',
                prevArrow: '<div class="slick-prev"></div>'
            });

        });

        $('.testimonials-slider > .slider').each(function () {

            var slider = $(this);

            slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow: '<div class="slick-next"></div>',
                prevArrow: '<div class="slick-prev"></div>'
            });

        });

    };

    var initLayeredNav = function () {

        var layered_nav = $('#layered_nav');

        $('.filter-toggle', layered_nav).click(function (e) {

            var $this = $(this),
                target = $this.data('target');

            layered_nav.find('.row-terms').slideUp(350);

            if (!$this.hasClass('expanded')) {
                layered_nav.find('.filter-toggle').removeClass('expanded');
                $this.addClass('expanded');
                layered_nav.find('#terms-' + target).slideToggle(350);
            } else {
                $this.removeClass('expanded');
            }

        });

    };

    var initMap = function () {

        $('.event-map').each(function () {
            map = new_map($(this));
        });

    };

    function new_map($el) {

        // var
        var $markers = $el.find('.marker');

        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        // create map
        var map = new google.maps.Map($el[0], args);

        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function () {
            add_marker($(this), map);
        });

        // center map
        center_map(map);

        // return
        return map;

    }

    function add_marker($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

                infowindow.open(map, marker);

            });
        }

    }

    function center_map(map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        }
        else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    }

})(jQuery); // Fully reference jQuery after this point.
